<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :accountId="$route.params.accountId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">Account Dashboard</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="error" v-if="!realm && isViewReady">
                        Not found
                    </v-alert>
                    <v-card v-if="realm">
                        <v-toolbar color="red darken-2 white--text" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Delete realm
                        </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>

                            <p class="text-overline mb-0">Display Name</p>
                            <p class="mb-0 pb-0">
                                {{ realm.display_name }}
                            </p>
                            <p class="text-overline mb-0 mt-2">Alias</p>
                            <p class="mb-0 pb-0">
                                {{ realm.alias }}
                            </p>

                            <v-alert type="warning" border="left" dense v-if="!userList && isViewReady" class="mt-4">
                                <template #prepend>
                                    <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                                </template>
                                <p class="font-weight-normal ml-4 mb-0">Failed to load user list for realm.</p>
                            </v-alert>

                            <template v-if="Array.isArray(userList)">
                            <p class="text-overline mb-0 mt-2">Active Users</p>
                            <p class="mb-0 pb-0" v-if="userList.length === 0">
                                No active users in this realm.
                            </p>
                            <p class="mb-0 pb-0" v-if="userList.length > 0">
                                This realm is currently used by <router-link :to="{ name: 'realm-user-list', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } }">{{ userList.length }} active users</router-link>.
                            </p>
                            </template>

                            <p class="text-overline mb-0 mt-4">Confirmation</p>

                            <p>Tap on the button below to delete this realm and associated data.</p>

                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation1" label="I want to delete this realm and all associated data." class="mt-0"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation2" label="I understand this action is not reversible, and that customer support will not be able to help me recover the data." class="mt-0"></v-checkbox>

                            <v-alert type="warning" border="left" dense>
                                <template #prepend>
                                    <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                                </template>
                                <p class="font-weight-bold ml-4 mb-0">This action is immediate and not reversible.</p>
                                <p class="font-weight-light ml-4 mb-0">If you change your mind, you will need to create a new realm and register new users.</p>
                            </v-alert>

                            <v-btn color="red" class="white--text" @click="deleteRealm" :disabled="!isDeleteRealmConfirmed">Delete Realm</v-btn>
                        </v-card-text>
                    </v-card>

                    <!-- <v-expansion-panels class="mt-8 mb-6" v-if="isPermitSystemAdmin">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>Tap on the button below to delete this profile, including all users, DNS records, and other related data.</p>
                                <p class="font-weight-bold">There will not be any confirmation prompts. This action is not reversible.</p>
                                <p class="mb-0 pb-0">
                                    <v-btn color="red" class="white--text" @click="deleteRealmImmediately">Delete Account</v-btn>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/RealmBar.vue';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
    },
    data: () => ({
        isViewReady: false,
        realm: null,
        forbiddenError: false,
        userList: null,
        error: null,
        deleteConfirmation1: false,
        deleteConfirmation2: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            focus: (state) => state.focus,
            isPermitSystemAdmin: 'isPermitSystemAdmin',
        }),
        isDeleteRealmConfirmed() {
            return this.deleteConfirmation1 && this.deleteConfirmation2;
        },
    },
    watch: {
        focus() {
            this.loadRealm();
        },
    },
    methods: {
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.get({ id: this.$route.params.realmId });
                console.log(`realm/dashboard.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/dashboard.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadUserList() {
            try {
                const match = { realm_id: this.$route.params.realmId };
                const result = await this.$client.account(this.$route.params.accountId).realmUser.search(match);
                console.log(`User.vue loadUserList result: ${JSON.stringify(result)}`);
                if (Array.isArray(result?.list)) {
                    this.userList = result.list;
                }
            } catch (err) {
                console.error('loadUserList failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load user list', message: `Realm ${this.$route.params.realmId}` });
                this.userList = null;
            }
        },
        onClickAccount(accountId) {
            this.$router.push({ name: 'account-dashboard', params: { accountId } });
        },
        async deleteRealm() {
            try {
                this.$store.commit('loading', { deleteRealm: true });
                const response = await this.$client.account(this.$route.params.accountId).realm.delete({ account_id: this.$route.params.accountId, realm_id: this.$route.params.realmId });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted realm' });
                    this.$router.replace({ name: 'account-dashboard', params: { account_id: this.$route.params.accountId } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete realm', message: this.$route.params.realmId });
                }
            } catch (err) {
                console.error('failed to delete realm', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete realm', message: this.$route.params.realmId });
            } finally {
                this.$store.commit('loading', { deleteRealm: false });
            }
        },
        /**
         * Service admin can delete the user profile and data immediately (TODO: not implemented yet)
         */
        /*
        async deleteRealmImmediately() {
            try {
                this.$store.commit('loading', { deleteRealmImmediately: true });
                const response = this.$client.account(this.$route.params.accountId).realm.delete();
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted account', message: this.$route.params.accountId });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
                }
            } catch (err) {
                console.error('failed to delete account', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
            } finally {
                this.$store.commit('loading', { deleteRealmImmediately: false });
            }
        },
        */
        async init() {
            try {
                // TODO: load these in parallel
                await this.loadRealm();
                await this.loadUserList();
            } catch (err) {
                console.error('init failed', err);
            } finally {
                this.isViewReady = true;
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
